import React, { useState } from "react";
import { IoMenu } from "react-icons/io5";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav
      className={`flex justify-between items-start p-6 py-5 ${
        isOpen ? "bg-kbg grid grid-cols-1 text-center rounded-lg" : ""
      }`}
    >
      <div className={`md:flex items-center ${isOpen ? "hidden" : ""}`}>
        <a href="/">
          <h1 className="md:text-2xl text-2xl font-medium">K&M</h1>
        </a>
      </div>
      <div className={`md:hidden ${isOpen ? "flex justify-end " : ""}`}>
        <IoMenu
          className="text-3xl cursor-pointer hover:text-gray-400 transition-all duration-300"
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      <div className={`md:flex ${isOpen ? "block" : "hidden"}`}>
        <ul
          className={`md:flex md:space-x-5 text-sm items-center justify-center ${
            isOpen ? "grid grid-cols-1 space-x-0 space-y-4" : ""
          }`}
        >
          <NavItem href="/#services">Services</NavItem>
          <p className="text-gray-400 hidden lg:block">/</p>
          <NavItem href="/work">Work</NavItem>
          <p className="text-gray-400 hidden lg:block">/</p>
          <NavItem href="/#about">About</NavItem>
          <p className="text-gray-400 hidden lg:block">/</p>
          <NavItem href="/#pricing">Pricing</NavItem>
          <NavItem href="/contact" button>
            Let's talk
          </NavItem>
        </ul>
      </div>
    </nav>
  );
};

function NavItem({ href, children, button }) {
  const baseClasses = "";
  const buttonClasses =
    "text-gray-950 px-5 py-2 rounded-full bg-gray-50 hover:text-gray-50 hover:bg-gray-800 transition-all duration-300";

  return (
    <a href={href}>
      <li
        className={`${
          !button ? baseClasses : buttonClasses
        } hover:text-gray-400 transition-all duration-300`}
      >
        {children}
      </li>
    </a>
  );
}

export default Navbar;
