import React from "react";
// import Navbar from "../shared/Navbar";
import Hero from "../HomeComp/Hero";
import About from "../HomeComp/About";
import Service from "../HomeComp/Service";
import Process from "../HomeComp/Process";
import Featured from "../HomeComp/Featured";
import GetStarted from "../HomeComp/GetStarted";
import Faq from "../HomeComp/Faq";
import Showcase from "../HomeComp/Showcase";
import Footer from "../shared/Footer";

const Home = () => {
  return (
    <div className="bg-[#ebebeb]">
      <Hero />
      <Service />
      <About />
      <Featured />
      <Process />
      <GetStarted />
      <Faq />
      <Showcase />
      <Footer />
    </div>
  );
};

export default Home;
