import React from "react";
import AboutPanel from "./Panels/AboutPanel";
import Marquee from "react-fast-marquee";

const Featured = () => {
  return (
    <div className="">
      <AboutPanel panel_name="Why be with K&M?" />

      <div className="m-3 rounded-lg">
        <div className="p-5 rounded-lg bg-gray-50">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-y-4 lg:gap-y-0 lg:gap-4">
            <div className="flex flex-col p-6 items-start justify-between space-y-12 bg-kbg rounded-lg text-gray-50">
              <h1 className="text-xl">Hiring and Retention Costs</h1>
              <div className="flex flex-col space-y-2">
                <h1 className="text-6xl lg:text-7xl font-medium">$115K+</h1>
                <p className="pr-1 text-base lg:text-lg">
                  Is the cost of hiring an expert AI Engineer (excluding taxes,
                  benefits, and bonus) according to Glassdoor.
                </p>
              </div>
            </div>
            <div className="flex flex-col p-6 items-start justify-between space-y-12 bg-kbg rounded-lg text-gray-50">
              <h1 className="text-xl">Infrastrucutre</h1>
              <div className="flex flex-col items-stretch space-y-2">
                <h1 className="text-6xl lg:text-7xl font-medium">
                  GPUs & TPUs
                </h1>
                <p className="pr-1 text-base lg:text-lg">
                  Setting up the necessary infrastructure and resources to
                  support AI/ML projects can be costly.
                </p>
              </div>
            </div>
            <div className="flex flex-col p-6 items-start justify-between space-y-12 bg-gray-200 rounded-lg text-gray-950">
              <h1 className="text-xl">Market Opportunity</h1>
              <div className="flex flex-col items-stretch space-y-2">
                <h1 className="text-6xl lg:text-8xl font-medium tracking-tighter">
                  &#8623;&#8623;
                </h1>
                <p className="pr-1 text-base lg:text-lg">
                  Startups that delay AI/ML integration miss out on market
                  opportunities and fall behind competitors.
                </p>
              </div>
            </div>
            <div className="flex flex-col p-6 items-start justify-between space-y-12 bg-gray-200 rounded-lg text-gray-950">
              <h1 className="text-xl">Maintainance Costs</h1>
              <div className="flex flex-col items-stretch space-y-2">
                <h1 className="text-6xl lg:text-7xl font-medium text-red-500">
                  $$ &#8593;
                </h1>
                <p className="pr-1 text-base lg:text-lg">
                  Developing a model is just one part of the whole story. Did
                  you know that a model starts to decay and needs to be
                  retrained with newer data?
                </p>
              </div>
            </div>
          </div>
          <div className="py-12 lg:py-24 p-5">
            <h1 className="text-3xl lg:text-7xl text-gray-400">
              So much cost with so little help!
              <br /> But we're here to solve{" "}
              <em className="underline underline-offset-8">just that</em>.
            </h1>
          </div>
          {/*grid grid-cols-1 lg:grid-cols-5 */}
          <div className="gap-2 lg:gap-y-0 lg:gap-4">
            <Marquee
              autoFill={true}
              pauseOnHover={true}
              speed={30}
              className=""
            >
              <div className="flex flex-col p-6 mx-3 items-start justify-between space-y-5 bg-kbg rounded-lg text-gray-50">
                <div className="flex flex-col space-y-2">
                  <h1 className="text-5xl lg:text-7xl font-medium">
                    $$ &#8595;
                  </h1>
                </div>
                <h1 className="text-xl lg:text-xl font-medium">
                  Reduced Costs
                </h1>
              </div>
              <div className="flex flex-col p-6 mx-3 items-start justify-between space-y-5 bg-kbg rounded-lg text-gray-50">
                <div className="flex flex-col items-stretch space-y-2">
                  <h1 className="text-5xl lg:text-7xl font-medium">
                    &lt;/&gt;
                  </h1>
                </div>
                <h1 className="text-xl lg:text-xl font-medium">
                  Access to Experts
                </h1>
              </div>
              <div className="flex flex-col p-6 mx-3 items-start justify-between space-y-5 bg-kbg rounded-lg text-gray-50">
                <div className="flex flex-col items-stretch space-y-2">
                  <h1 className="text-5xl lg:text-7xl font-medium tracking-tighter">
                    &#8682;
                  </h1>
                </div>
                <h1 className="text-xl lg:text-xl font-medium">
                  Faster Time to Market
                </h1>
              </div>
              <div className="flex flex-col p-6 mx-3 items-start justify-between space-y-5 bg-kbg rounded-lg text-gray-50">
                <div className="flex flex-col items-stretch space-y-2">
                  <h1 className="text-5xl lg:text-7xl font-medium ">&#8689;</h1>
                </div>
                <h1 className="text-xl lg:text-xl font-medium">
                  Scalability and Flexibility
                </h1>
              </div>
              <div className="flex flex-col p-6 mx-3 items-start justify-between space-y-5 bg-kbg rounded-lg text-gray-50">
                <div className="flex flex-col items-stretch space-y-2">
                  <h1 className="text-5xl lg:text-7xl font-medium ">&#8635;</h1>
                </div>
                <h1 className="text-xl lg:text-xl font-medium">
                  Unlimited Revisions
                </h1>
              </div>
              <div className="flex flex-col p-6 mx-3 items-start justify-between space-y-5 bg-kbg rounded-lg text-gray-50">
                <div className="flex flex-col items-stretch space-y-2">
                  <h1 className="text-5xl lg:text-7xl font-medium text-green-500">
                    &#9829;
                  </h1>
                </div>
                <h1 className="text-xl font-medium">Reduced Risk</h1>
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Featured;
