import React from "react";
import bgFooter from "../../assets/bg-footer.png";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <div className="m-3 bg-kbg text-gray-50 rounded-lg relative z-0">
      <div className="flex flex-col space-y-8 ">
        <div className="flex flex-col p-5 lg:p-12 items-center justify-center space-y-8">
          <div className="w-full flex space-x-3 py-5 px-2 md:px-5 md:py-4 justify-center items-center">
            <span className="relative flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-200 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-gray-300"></span>
            </span>
            <p className="">Empowering businesses with smarter AI</p>
          </div>
          <div className="text-center">
            <h1 className="text-3xl font-medium lg:font-normal lg:text-8xl pb-8">
              Brainpower on tap.
            </h1>
            <a
              href="/contact"
              className="px-5 py-2 text-lg bg-gray-50 text-gray-950 hover:bg-gray-800 hover:text-gray-50 rounded-full transition-all duration-300"
            >
              Let's talk
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-12 lg:gap-y-0 p-5 text-gray-400 text-center">
          <div className="flex flex-col justify-center items-center space-y-4 lg:space-y-2">
            <div className="flex space-x-2 justify-center items-center">
              <p className="">Drop an email:</p>
              <a
                href="mailto:hello@knmfirm.com"
                className="font-medium underline underline-offset-8"
              >
                hello@knmfirm.com
              </a>
            </div>
            <h1 className="text-lg">&copy; {year}, Kulthe Mishra, Limited</h1>
          </div>

          <div className="">
            <ul className="flex space-x-4 justify-center items-center text-sm md:text-base">
              <a href="/" className="hover:underline underline-offset-8">
                <li>Home</li>
              </a>
              <a href="/#about" className="hover:underline underline-offset-8">
                <li>About</li>
              </a>
              <a href="/contact" className="hover:underline underline-offset-8">
                <li>Contact</li>
              </a>
              <a href="/privacy" className="hover:underline underline-offset-8">
                <li>Privacy</li>
              </a>
              <a href="/terms" className="hover:underline underline-offset-8">
                <li>Terms</li>
              </a>
            </ul>
          </div>
        </div>
        <div>
          <div className="">
            <div className="lg:h-52 pb-3 lg:pb-0 overflow-hidden">
              <h1 className="font-semibold text-[7rem] lg:text-[14rem] text-gray-400 tracking-tight text-center">
                K&M
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute inset-0 bg-cover bg-center brightness-75 bg-blend-darken -z-10"
        style={{ backgroundImage: `url(${bgFooter})` }}
        alt="frame grid cover"
      ></div>
    </div>
  );
};

export default Footer;
