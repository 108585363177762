import React, { useEffect, useState } from "react";
import AboutPanel from "./Panels/AboutPanel";
import Pricing from "./Pricing";

const GetStarted = () => {
  const month_name = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date();
  let month = month_name[date.getMonth()];
  const dayOfMonth = date.getDate();

  const [remainingSpots, setRemainingSpots] = useState(2);

  useEffect(() => {
    let updatedSpots;
    if (dayOfMonth <= 5) {
      updatedSpots = "5";
    } else if (dayOfMonth <= 12) {
      updatedSpots = "4/5";
    } else if (dayOfMonth <= 18) {
      updatedSpots = "3/5";
    } else if (dayOfMonth <= 24) {
      updatedSpots = "2/5";
    } else {
      updatedSpots = "1/5";
    }
    setRemainingSpots(updatedSpots);
  }, [dayOfMonth]);
  return (
    <div>
      <AboutPanel panel_name="Get Started" />
      <div
        className="m-3 rounded-lg bg-gray-50 text-gray-950 pt-5 px-5 flex flex-col space-y-1"
        id="pricing"
      >
        <div className="grid grid-cols-1 divide-y-2 border-gray-500">
          <div className="flex p-5 flex-col justify-center items-center text-center space-y-5 pb-6 lg:pb-8">
            <h1 className="capitalize text-3xl lg:text-5xl max-w-md font-medium">
              Our pricing is as simple as it gets.
            </h1>
            <p className="text-gray-600">
              No surprises, no hidden fees. Just straight-forward awesomeness.
            </p>
          </div>
          <Pricing />
          <div className="pt-5 px-5 pb-5 lg:pb-0 overflow-hidden">
            <div className="lg:h-24 overflow-hidden">
              <h1 className="font-semibold lg:font-bold text-[2.5rem] lg:text-[6.6rem] text-gray-200 tracking-tight text-center leading-tight">
                <span className="text-gray-400">{remainingSpots}</span> Spots
                Left For <span className="text-gray-400">{month}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
