import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import LetsTalk from "./components/pages/LetsTalk";
import Work from "./components/pages/Work";
import Article from "./components/WorkComp/Article";
import Privacy from "./components/pages/Privacy";
import Terms from "./components/pages/Terms";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<LetsTalk />} />
        <Route path="/work" element={<Work />} />
        <Route path="/blog/:slug" element={<Article />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
