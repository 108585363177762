// const blogCards = [
//   {
//     id: "1",
//     slug: "innovative-stock-market-prediction-project",
//     tag: "Tutorial",
//     date: "02 Jan, 2024",
//     title:
//       "How to quickly deploy a static website. And this is going pretty well.",
//     abstract:
//       "Static websites are now used to bootstrap lots of websites and are becoming the basis for a variety of tools that even influence both web designers and developers influence both web designers and developers.",
//     link: "/",
//     author: "Siddhesh K&M",
//     main_img: "https://dr.savee-cdn.com/things/6/4/8fff87130b0c1571dc18e0.webp",
//     author_img:
//       "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png",
//   },
//   {
//     id: "2",
//     slug: "innovative-stock-market-prediction-project-2",
//     tag: "Tutorial",
//     date: "02 Jan, 2024",
//     title: "Evaluation of T-BERT Framework for Sentiment Analysis",
//     abstract:
//       "Static websites are now used to bootstrap lots of websites and are becoming the basis for a variety of tools that even influence both web designers and developers influence both web designers and developers.",
//     link: "/",
//     author: "Siddhesh K&M",
//     main_img: "https://dr.savee-cdn.com/things/6/4/8fff87130b0c1571dc18e0.webp",
//     author_img:
//       "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png",
//   },
//   {
//     id: "3",
//     slug: "innovative-stock-market-prediction-project-2",
//     tag: "Tutorial",
//     date: "02 Jan, 2024",
//     title: "How to quickly deploy a static website",
//     abstract:
//       "Static websites are now used to bootstrap lots of websites and are becoming the basis for a variety of tools that even influence both web designers and developers influence both web designers and developers.",
//     link: "/",
//     author: "Siddhesh K&M",
//     main_img: "https://dr.savee-cdn.com/things/6/4/fdd9abde2cb753778f4490.png",
//     author_img:
//       "https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png",
//   },
// ];

// export default blogCards;

const blogCards = [
  {
    id: "1",
    slug: "innovative-stock-market-prediction-project",
    tag: "Stock Price Prediction",
    date: "04 Sep, 2023",
    title:
      "Innovative Stock Market Prediction Project: K&M's Experiment and Achievement",
    abstract:
      "In the dynamic stock exchange market, making informed decisions about share investments demands accurate predictions of future market values. K&M Firm undertook a groundbreaking Two-month project, leveraging advanced machine learning techniques to outperform traditional stock prediction methods. This venture, conducted for a valued client, showcased our commitment to excellence and innovation.",
    link: "/",
    author: "Siddhesh Kulthe",
    main_img:
      "https://images.pexels.com/photos/6801648/pexels-photo-6801648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    author_img:
      "https://media.licdn.com/dms/image/D4D03AQGc5F8mALwwUQ/profile-displayphoto-shrink_200_200/0/1691826956500?e=2147483647&v=beta&t=MNQWRaAoUbEkqlJWNLmVANtcTqRJmipLx7JhY8uec9Y",
  },
  {
    id: "2",
    slug: "t-bert-framework-for-sentiment-analysis",
    tag: "Sentimental Analysis",
    date: "19 Oct, 2024",
    title:
      "Implementation and Evaluation of T-BERT Framework for Sentiment Analysis: A Practical Experiment",
    abstract:
      "This paper implements T-BERT for sentiment analysis in 42,000 real-world microblogs from Twitter. Unaffiliated with IEEE, the study combines LDA and BERT embeddings, fine-tunes a sentiment analysis model, and demonstrates improved accuracy and topics clustering. Results affirm T-BERT's practical utility beyond the original research paper, offering insights for diverse applications.",
    link: "/",
    author: "James Smith",
    main_img:
      "https://images.pexels.com/photos/267389/pexels-photo-267389.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    author_img:
      "https://images.pexels.com/photos/2085832/pexels-photo-2085832.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    id: "3",
    slug: "autoencoder-based-zero-day-attack-detection",
    tag: "Attack Detection",
    date: "13 Nov, 2024",
    title:
      "Advancing Cybersecurity: Autoencoder-Based Zero-Day Attack Detection",
    abstract:
      "In response to the escalating challenge of zero-day cyber-attacks, this research pioneers an innovative approach utilizing autoencoder-based Deep Learning techniques for Intrusion Detection Systems (IDS). Traditional IDS solutions, reliant on historical attack signatures, face limitations in detecting evolving threats. Our proposed autoencoder model aims to enhance IDS capabilities, focusing on high recall while minimizing false negatives. Evaluation on CICIDS2017 and NSL-KDD datasets demonstrates its efficacy in identifying complex zero-day attacks, achieving 89–99% accuracy for NSL-KDD and 75–98% for CICIDS2017.",
    link: "/",
    author: "Siddhesh Kulthe",
    main_img:
      "https://images.pexels.com/photos/5380665/pexels-photo-5380665.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    author_img:
      "https://media.licdn.com/dms/image/D4D03AQGc5F8mALwwUQ/profile-displayphoto-shrink_200_200/0/1691826956500?e=2147483647&v=beta&t=MNQWRaAoUbEkqlJWNLmVANtcTqRJmipLx7JhY8uec9Y",
  },
  {
    id: "4",
    slug: "ml-combat-fake-reviews",
    tag: "Sentimental Analysis",
    date: "21 Nov, 2023",
    title:
      "Leveraging Sentiment Analysis and Machine Learning to Combat Fake Reviews",
    abstract:
      "Sentiment Analysis (SA) has become a focal point in text analysis due to its commercial benefits. In this groundbreaking project, conducted by K&M Firm in collaboration with Elshrif Elmurngi and Abdelouahed Gherbi from the Department of Software and IT Engineering at École de Technologie Supérieure, Montreal, Canada, we delve into the realm of sentiment analysis to detect fake reviews. The project aims to classify movie reviews into genuine and fake categories using cutting-edge machine learning algorithms.",
    link: "/",
    author: "James Smith",
    main_img:
      "https://images.pexels.com/photos/230544/pexels-photo-230544.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    author_img:
      "https://images.pexels.com/photos/2085832/pexels-photo-2085832.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
];

export default blogCards;
