import React from "react";
import Navbar from "../../shared/Navbar";

const SharedHero = ({ title, subtitle, cover }) => {
  return (
    <div className="m-3 bg-kbg rounded-lg text-gray-50 lg:h-screen flex flex-col relative z-0">
      <Navbar />
      <div className="lg:flex-1 flex items-center justify-center my-32 md:my-0 relative z-10">
        <div className="flex flex-col text-center items-center justify-center space-y-10 max-w-7xl lg:text-center px-6">
          <h1 className="text-4xl lg:text-8xl font-medium">{title}</h1>
          <p className="text-sm md:text-xl lg:max-w-2xl px-2">{subtitle}</p>
        </div>
      </div>
      <div className="absolute inset-0 object-cover w-full h-full -z-10 brightness-50 opacity-10 rounded-lg overflow-hidden">
        <img
          src={cover}
          alt="cover"
          className="object-contain w-full h-full scale-50"
        />
      </div>
    </div>
  );
};

export default SharedHero;
