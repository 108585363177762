import { IoIosCheckmarkCircle } from "react-icons/io";

const Pricing = () => {
  const benefits = [
    "Unlimited model development",
    "Unlimited EDA",
    "Unlimited retraining",
    "Pattern recognition tasks",
    "N* Senior developer (5+ years exp)",
    "1x Project manager",
    "Bug fixes",
    "Concept & ideation",
    "Regular updates",
    "24/7 Support",
  ];

  const oneShotBenefits = [
    "Valid till project length",
    "N* Senior developer (5+ years exp)",
    "1x Project manager",
    "Bug fixes",
    "24/7 Support",
  ];

  return (
    <div className="lg:px-5 py-6 lg:py-16 w-full">
      <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-4 gap-y-4 lg:gap-y-0">
        <div className="bg-kbg rounded-lg p-2 lg:p-7 flex flex-col space-y-3 lg:space-y-5 lg:col-span-3 text-gray-50">
          <div className="flex flex-col space-y-5 items-start justify-start border-b-2 border-gray-800 p-5">
            <p className="px-4 py-1.5 uppercase text-xs rounded-full border border-gray-50">
              Special Offer
            </p>
            <h1 className="text-xl lg:text-3xl max-w-md font-medium">
              Startups Only
            </h1>
            <p className="py-3 max-w-md">
              Apply for the fastest time-to-market firm in AI development.
              Limited seats available.
            </p>
          </div>
          <div>
            <div className="py-4 lg:py-7">
              <ul className="grid grid-cols-1 lg:grid-cols-2 justify-center items-center text-left gap-4 p-5">
                {benefits.map((benefit, index) => (
                  <li className="flex items-center justify-start" key={index}>
                    <span className="w-5 mr-2 text-green-500">
                      <IoIosCheckmarkCircle />
                    </span>
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center p-5 space-y-4 lg:space-y-0">
              <div className="flex justify-start">
                <h1 className="text-lg w-full text-center lg:text-left lg:text-2xl font-medium flex items-start">
                  50% discounted fee<span className="text-lg">**</span>
                </h1>
              </div>
              <div className="flex w-full text-center lg:justify-end">
                <a
                  href="/contact"
                  className="px-5 w-full lg:w-auto py-2 rounded-full text-gray-950 bg-gray-50 hover:text-gray-50 hover:bg-gray-800 transition-all duration-300"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 rounded-lg p-2 lg:p-7 flex flex-col space-y-3 lg:space-y-5 lg:col-span-2 text-gray-950">
          <div className="flex flex-col space-y-5 items-start justify-start border-b-2 border-gray-300 p-5">
            <p className="px-4 py-1.5 uppercase text-xs rounded-full border border-gray-950">
              dynamic & powerful
            </p>
            <h1 className="text-xl lg:text-3xl max-w-md font-medium">
              Enterprise
            </h1>
            <p className="py-3 max-w-md">
              Cost effective development at scale for fixed projects. We've
              thought of it all.
            </p>
          </div>
          <div>
            <div className="py-4 lg:py-7">
              <ul className="grid grid-cols-1 justify-center items-center text-left gap-4 p-5">
                {oneShotBenefits.map((benefit, index) => (
                  <li className="flex items-center justify-start" key={index}>
                    <span className="w-5 mr-2 text-green-500">
                      <IoIosCheckmarkCircle />
                    </span>
                    {benefit}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 items-center justify-center p-5 space-y-4 lg:space-y-0">
              <div className="flex justify-start">
                {/* <h1 className="text-2xl w-full text-center lg:text-left lg:text-3xl font-medium"></h1> */}
              </div>
              <div className="flex w-full text-center lg:justify-end">
                <a
                  href="/contact"
                  className="px-5 w-full lg:w-auto py-2 rounded-full text-gray-50 bg-gray-950 hover:text-gray-950 hover:bg-gray-200 transition-all duration-300"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-6">
        <p className="text-center text-sm text-gray-700">
          * senior developers will be allocated as per your company's needs. It
          can be one, ten, or even a hundred! Just ask.
          <br />
          ** applicable for startups incorporated less than two years ago, and
          funded less than six months ago.
        </p>
      </div>
    </div>
  );
};

export default Pricing;
