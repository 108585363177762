import React from "react";
import SharedHero from "../HomeComp/Panels/SharedHero";
import Blog from "../WorkComp/Blog";
import workCover from "../../assets/work-cover.svg";
import Footer from "../shared/Footer";

const Work = () => {
  return (
    <div className="bg-[#ebebeb]">
      <SharedHero
        title={
          <>
            Pure work. <br />
            In it's perfect state.
          </>
        }
        subtitle={
          "Beautifully hand-crafted case studies that explain real life situations and how they were tackled by our team at K&M"
        }
        cover={workCover}
      />
      <Blog />
      <Footer />
    </div>
  );
};

export default Work;
