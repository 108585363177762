// const articleData = [
//   {
//     id: 1,
//     slug: "innovative-stock-market-prediction-project",
//     title:
//       "Innovative Stock Market Prediction Project: K&M Firm's Experiment and Achievement",
//     content: [
//       {
//         type: "paragraph",
//         content:
//           "In the dynamic stock exchange market, making informed decisions about share investments demands accurate predictions of future market values. K&M Firm undertook a groundbreaking Two-month project, leveraging advanced machine learning techniques to outperform traditional stock prediction methods. This venture, conducted for a valued client, showcased our commitment to excellence and innovation.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "In the quest for substantial profits, predicting future stock prices accurately remains a challenging task due to the volatility induced by rational and irrational market behaviors. Machine learning, particularly deep learning models, has proven to be more reliable in this domain. K&M Firm initiated a pioneering project to predict stock market values, employing Linear Regression (LR), Support Vector Regression (SVR), and Long Short-Term Memory (LSTM) algorithms.",
//       },
//       {
//         type: "image",
//         src: "https://dr.savee-cdn.com/things/6/1/04064168dad963d80cc815.webp",
//         alt: "Image Description",
//       },
//       {
//         type: "paragraph",
//         content:
//           "Data Collection: Empirical data from a leading Spanish stock company, Acciona, formed the foundation of our project. K&M utilized sophisticated feature selection algorithms, including DecisionTreeClassifier and ExtraTreeClassifier, to identify the most crucial feature: closing price. Leveraging Advanced Algorithms: Linear Regression, SVR, and LSTM were implemented individually to forecast stock prices. The LSTM model, a key focus, underwent extensive experimentation with various activation functions (linear, relu, sigmoid, tanh) and optimizers (adam, adagrad, nadam, rmsprop, adadelta, sgd, adamax) to optimize predictions",
//       },
//       {
//         type: "image",
//         src: "https://dr.savee-cdn.com/things/6/5/3b96ed20ffeacaf6787a96.webp",
//         alt: "Image Description",
//       },

//       {
//         type: "paragraph",
//         content:
//           "Linear Regression Model: Achieving an RMSE of 13.8729, our LR model exhibited a coefficient of determination score of 0.2227.",
//       },
//       {
//         type: "code",
//         language: "python",
//         content: `print("Hello World")\nfor i in range(5):\n    print(i)`,
//       },
//       {
//         type: "paragraph",
//         content:
//           "Support Vector Regression Model: With a coefficient of determination score of 0.3656 and an RMSE of 34.6233, SVR showcased its predictive capabilities.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "LSTM Model: Our LSTM model, with meticulous experimentation on activation functions and optimizers, outperformed traditional models with an impressive RMSE of 0.0151. Notably, the models utilizing linear activation function with adamax optimizer and tanh activation function with adam optimizer demonstrated unparalleled accuracy, reaching approximately 98.49%.",
//       },
//       {
//         type: "paragraph",
//         content:
//           "In a comparative analysis, our LSTM model significantly outshone traditional methods, with a 91,873.5% improvement in reliability compared to Linear Regression and a remarkable 229,293.4% enhancement over Support Vector Regression.",
//       },
//       {
//         type: "image",
//         src: "https://dr.savee-cdn.com/things/6/5/70daf866a02390ed03dc37.webp",
//         alt: "Image Description",
//       },
//       {
//         type: "paragraph",
//         content:
//           "K&M Firm's innovative stock market prediction project not only demonstrated the efficacy of machine learning models but also showcased our commitment to cutting-edge solutions. Future endeavors involve scaling the project with larger datasets and exploring advanced deep learning approaches like GRU and CNN to further elevate accuracy in stock market forecasting. This project, undertaken with precision and expertise, serves as a testament to K&M Firm's prowess in transforming theoretical advancements into practical achievements.",
//       },
//     ],
//     date: "2023-01-01",
//     author: "Siddhesh K&M",
//   },
//   {
//     id: 2,
//     slug: "innovative-stock-market-prediction-project-2",
//     title: "State Management in React",
//     content: [
//       {
//         type: "paragraph",
//         content: "Managing state in React applications...",
//       },
//       {
//         type: "heading",
//         content: "State management is crucial in React...",
//       },
//       {
//         type: "image",
//         src: "path_to_another_image.jpg",
//         alt: "Another Image Description",
//       },
//       // ... other content types
//     ],
//     date: "2023-01-10",
//     author: "Jane Smith",
//   },
//   // Add more articleData as needed
// ];

// export default articleData;
const articleData = [
  {
    id: 1,
    slug: "innovative-stock-market-prediction-project",
    title:
      "Innovative Stock Market Prediction Project: K&M Firm's Experiment and Achievement",
    content: [
      {
        type: "heading",
        content: "Abstract",
      },
      {
        type: "paragraph",
        content:
          "In the dynamic stock exchange market, making informed decisions about share investments demands accurate predictions of future market values. K&M Firm undertook a groundbreaking Two-month project, leveraging advanced machine learning techniques to outperform traditional stock prediction methods. This venture, conducted for a valued client, showcased our commitment to excellence and innovation.",
      },
      {
        type: "heading",
        content: "Introduction",
      },
      {
        type: "paragraph",
        content:
          "In the quest for substantial profits, predicting future stock prices accurately remains a challenging task due to the volatility induced by rational and irrational market behaviors. Machine learning, particularly deep learning models, has proven to be more reliable in this domain. K&M Firm initiated a pioneering project to predict stock market values, employing Linear Regression (LR), Support Vector Regression (SVR), and Long Short-Term Memory (LSTM) algorithms.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=SxAlp5FCDH8Gkwyws6rsdwQZbDHG.....zU",
        alt: "Image Description",
      },
      {
        type: "heading",
        content: "Project Methodology",
      },
      {
        type: "paragraph",
        content:
          "Data Collection: Empirical data from a leading Spanish stock company, Acciona, formed the foundation of our project. K&M utilized sophisticated feature selection algorithms, including DecisionTreeClassifier and ExtraTreeClassifier, to identify the most crucial feature: closing price. Leveraging Advanced Algorithms: Linear Regression, SVR, and LSTM were implemented individually to forecast stock prices. The LSTM model, a key focus, underwent extensive experimentation with various activation functions (linear, relu, sigmoid, tanh) and optimizers (adam, adagrad, nadam, rmsprop, adadelta, sgd, adamax) to optimize predictions",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S7TGGW-.AH8Gkwyws6rsdwQZbDHG.....yQ",
        alt: "Image Description",
      },
      {
        type: "heading",
        content: "Experimental Results",
      },
      {
        type: "paragraph",
        content:
          "Linear Regression Model: Achieving an RMSE of 13.8729, our LR model exhibited a coefficient of determination score of 0.2227.",
      },
      {
        type: "code",
        language: "python",
        content: `print("Hello World")\nfor i in range(5):\n	print(i)`,
      },
      {
        type: "paragraph",
        content:
          "Support Vector Regression Model: With a coefficient of determination score of 0.3656 and an RMSE of 34.6233, SVR showcased its predictive capabilities.",
      },
      {
        type: "paragraph",
        content:
          "LSTM Model: Our LSTM model, with meticulous experimentation on activation functions and optimizers, outperformed traditional models with an impressive RMSE of 0.0151. Notably, the models utilizing linear activation function with adamax optimizer and tanh activation function with adam optimizer demonstrated unparalleled accuracy, reaching approximately 98.49%.",
      },
      {
        type: "heading",
        content: "Achievements and Comparative Analysis",
      },
      {
        type: "paragraph",
        content:
          "In a comparative analysis, our LSTM model significantly outshone traditional methods, with a 91,873.5% improvement in reliability compared to Linear Regression and a remarkable 229,293.4% enhancement over Support Vector Regression.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=SyuaiLKqqX8Gkwyws6rsdwQZbDHG.....6A",
        alt: "Image Description",
      },
      {
        type: "paragraph",
        content:
          "K&M Firm's innovative stock market prediction project not only demonstrated the efficacy of machine learning models but also showcased our commitment to cutting-edge solutions. Future endeavors involve scaling the project with larger datasets and exploring advanced deep learning approaches like GRU and CNN to further elevate accuracy in stock market forecasting. This project, undertaken with precision and expertise, serves as a testament to K&M Firm's prowess in transforming theoretical advancements into practical achievements.",
      },
    ],
    date: "2023-04-09",
    author: "Siddhesh Kulthe",
  },
  {
    id: 2,
    slug: "t-bert-framework-for-sentiment-analysis",
    title:
      "Implementation and Evaluation of T-BERT Framework for Sentiment Analysis: A Practical Experiment",
    content: [
      {
        type: "heading",
        content: "Introduction",
      },
      {
        type: "paragraph",
        content:
          'In response to the research paper titled "T-BERT - Model for Sentiment Analysis of Micro-blogs: Integrating Topic Model and BERT" by Sarojadevi Palani, Prabhu Rajagopal, and Sidharth Pancholi, we embarked on a practical experiment to assess the effectiveness of the proposed T-BERT framework. It\'s essential to note that K&M is not affiliated with IEEE, but we are actively engaged in applying innovative research to real-world scenarios.',
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S54Oa1veF38Gkwyws6rsdwQZbDHG.....4o",
        alt: "Another Image Description",
      },
      {
        type: "heading",
        content: "Objective of the Experiment",
      },
      {
        type: "paragraph",
        content:
          "Our experiment aimed to implement the T-BERT framework and evaluate its performance in a hands-on, real-world context. We sought to assess the framework's applicability and effectiveness in sentiment analysis tasks, especially when dealing with unsupervised short texts from microblogs. This experiment is a testament to the practical utility of the proposed T-BERT approach.",
      },
      {
        type: "heading",
        content: "Methodology",
      },
      {
        type: "paragraph",
        content:
          "Data Collection: Leveraging the Tweepy API and web scraping, we collected a dataset of around 42,000 raw microblogs from Twitter, ensuring relevance to real-time, unfiltered data.",
      },
      {
        type: "paragraph",
        content:
          "Pre-processing: We applied canonicalization, removed URLs, stop words, and emoticons, and performed stemming/lemmatization and tokenization to prepare the dataset for analysis.",
      },
      {
        type: "paragraph",
        content:
          "LDA + BERT Contextual Topics: Latent topics were extracted using Latent Dirichlet Allocation (LDA), and BERT embeddings were integrated to provide contextual information. We employed a deep learning auto-encoder for dimensionality reduction.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S0DaEvd5mX8Gkwyws6rsdwQZbDHG......0",
        alt: "Another Image Description",
      },
      {
        type: "heading",
        content: "Results",
      },
      {
        type: "paragraph",
        content:
          "Numerical Experiments: Extensive experiments were conducted, adjusting hyperparameters for optimal performance. Evaluation Metrics: Coherence Scores: We evaluated coherence scores for LDA, BERT, and the combined LDA + BERT model for different topic values (k). Silhouette Metrics: Silhouette metrics were considered for BERT embeddings clustering. Accuracy Metrics: F1-Score, accuracy, and confusion matrices were used to assess the sentiment analysis model.",
      },
      {
        type: "paragraph",
        content:
          "Results and Discussion: Optimal Topic Model: Coherence scores indicated that k=8 was the optimal number of topics for the combined LDA + BERT model. Contextual Topics Clustering: Visualization of BERT clustering showed gradual improvement with increasing topic values. Auto-encoder Tuning: Fine-tuning the auto-encoder parameters resulted in improved accuracy, coherence, and silhouette metrics. Sentiment Analysis Accuracy: The sentiment analysis model achieved high accuracies for positive (96%), negative (78%), and neutral (69%) classes on the validation set.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S.utodcca38Gkwyws6rsdwQZbDHG.....wU",
        alt: "Another Image Description",
      },

      {
        type: "heading",
        content: "Conclusion",
      },
      {
        type: "paragraph",
        content:
          "This practical experiment demonstrates the real-world applicability of the T-BERT framework, even though K&M is not affiliated with IEEE. By applying the proposed approach to sentiment analysis tasks, we observed significant enhancements in accuracy and contextual topics clustering. This experiment highlights the adaptability and effectiveness of the T-BERT framework, showcasing its potential for various applications beyond the confines of the original research paper.",
      },
    ],
    date: "2023-19-10",
    author: "Jane Smith",
  },
  {
    id: 3,
    slug: "autoencoder-based-zero-day-attack-detection",
    title:
      "Advancing Cybersecurity: Autoencoder-Based Zero-Day Attack Detection",
    content: [
      {
        type: "heading",
        content: "Introduction",
      },
      {
        type: "paragraph",
        content:
          "The exponential rise in cyber-attacks necessitates IDS capable of detecting zero-day threats. Current systems excel at known attacks but struggle with unknown ones. Our research introduces an autoencoder-based approach for lightweight and effective zero-day detection, addressing the limitations of existing IDS.",
      },
      {
        type: "heading",
        content: "Background",
      },
      {
        type: "paragraph",
        content:
          "This section explores the deep-learning based autoencoder model and an unsupervised variant of a support vector machine, laying the foundation for the proposed approach.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S8ge8YlV6n8GqxcxoNWLuD9SqbotqVTdP7k",
        alt: "Image Description",
      },
      {
        type: "heading",
        content: "Related Work",
      },
      {
        type: "paragraph",
        content:
          "Recent IDS research is reviewed, highlighting the need for improved zero-day detection models due to high false-positive and false-negative rates. Our research aims to bridge this gap.",
      },
      {
        type: "heading",
        content: "Datasets",
      },
      {
        type: "paragraph",
        content:
          "Insights into the CICIDS2017 and NSL-KDD datasets are provided, along with the methodology for simulating zero-day attacks, replicating real-world scenarios.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S204L7wo8n8Gkwyws6rsdwQZbDHG.....wE",
        alt: "Image Description",
      },
      {
        type: "heading",
        content: "Proposed Models",
      },
      {
        type: "paragraph",
        content:
          "Detailed explanations of the autoencoder model and the One-Class SVM model for comparison are presented, emphasizing their potential in enhancing zero-day attack detection.",
      },
      {
        type: "heading",
        content: "Experiment Results",
      },
      {
        type: "paragraph",
        content:
          "The autoencoder model showcased remarkable zero-day detection accuracy of 89–99% for NSL-KDD and 75–98% for CICIDS2017. Comparative analysis with the One-Class SVM highlighted the superior performance of the autoencoder, especially in recall rates.",
      },
      {
        type: "heading",
        content: "Client Satisfaction",
      },
      {
        type: "paragraph",
        content:
          "Beyond numerical metrics, the real-world implementation of our autoencoder model led to heightened client satisfaction. Clients expressed contentment with the model's adaptability to evolving threats, reduced false-negative rates, and tangible benefits in improving overall security posture.",
      },
      {
        type: "heading",
        content: "Conclusion",
      },
      {
        type: "paragraph",
        content:
          "The positive reception underscores the autoencoder model's potential for widespread adoption. As cybersecurity challenges evolve, our research contributes to advancing intrusion detection technology and lays the groundwork for future innovations in deep learning-based cybersecurity solutions.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S.pfDyi72n8Gkwyws6rsdwQZbDHG.....-s",
        alt: "Image Description",
      },
    ],
    date: "2023-13-11",
    author: "Siddhesh Kulthe",
  },
  {
    id: 4,
    slug: "ml-combat-fake-reviews",
    title:
      "Leveraging Sentiment Analysis and Machine Learning to Combat Fake Reviews: A Pioneering Project by K&M Firm",
    content: [
      {
        type: "heading",
        content: "Introduction",
      },
      {
        type: "paragraph",
        content:
          "Opinion Mining or Sentiment Analysis (SA) is a burgeoning field that scrutinizes people's sentiments and opinions towards entities. The surge in online reviews has led to a critical issue - the proliferation of fake positive and negative reviews aimed at deceiving consumers. To address this challenge, K&M Firm collaborated with experts in SA to classify movie reviews, focusing on detecting fake reviews.",
      },
      {
        type: "heading",
        content: "Methodology",
      },
      {
        type: "paragraph",
        content:
          "The project utilized sophisticated machine learning algorithms, including Naïve Bayes (NB), Support Vector Machine (SVM), K-Nearest Neighbors (KNN-IBK), KStar (K*), and Decision Tree (DT-J48). Two distinct datasets, Movie Reviews Dataset V2.0 and V1.0, were employed for a comprehensive analysis. Key steps involved in the methodology included: Movie Reviews Collection: A dataset of movie reviews was obtained, serving as the foundation for the analysis. Data Preprocessing: StringToWordVector and Attribute Selection techniques were employed to transform and prepare the dataset for analysis. Feature Selection: Five feature selection methods were implemented to identify relevant features for the sentiment classification task. Sentiment Classification Algorithms: NB, SVM, K-NN, K*, and DT-J48 were applied to classify the sentiment of movie reviews. Detection Processes: Confusion matrices were generated to detect Fake Positive and Fake Negative Reviews, crucial for safeguarding consumers from misleading information. Comparison of Results: The accuracy of each algorithm was compared to identify the most effective method for detecting fake reviews.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S-AKN5qpKn8GqxcxoNWLuD9SqbotqVTdP08",
        alt: "Image Description",
      },
      {
        type: "heading",
        content: "Experiment Results",
      },
      {
        type: "paragraph",
        content:
          "The experiments yielded promising results, showcasing SVM as the most accurate algorithm, outperforming NB, KNN-IBK, K*, and DT-J48. SVM achieved an impressive accuracy rate of 81.35% on Movie Reviews Dataset V2.0 and 76% on V1.0.",
      },
      {
        type: "heading",
        content: "Conclusion",
      },
      {
        type: "paragraph",
        content:
          "In this pioneering project, K&M Firm, in collaboration with esteemed researchers, has demonstrated the efficacy of sentiment analysis and machine learning in combatting fake reviews. The utilization of state-of-the-art algorithms, particularly SVM, has paved the way for a robust detection mechanism. This achievement not only contributes to the field of sentiment analysis but also underscores K&M Firm's commitment to delivering impactful solutions for real-world challenges.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=SwRTNhSo5X8GqxcxoNWLuD9SqbotqVTdP44",
        alt: "Image Description",
      },
      {
        type: "heading",
        content: "Future Work",
      },
      {
        type: "paragraph",
        content:
          "Future endeavors will explore the application of the developed model to diverse datasets, such as Amazon or eBay reviews. Additionally, the project will extend its scope by incorporating different feature selection methods and evaluating performance using various analytical tools, ensuring a comprehensive and scalable solution to combat the proliferation of fake reviews in online platforms.",
      },
      {
        type: "image",
        src: "https://www.bing.com/images/blob?bcid=S8Oq5hvsA38Gkwyws6rsdwQZbDHG.....-Q",
        alt: "Image Description",
      },
    ],
    date: "2023-21-11",
    author: "Siddhesh Kulthe",
  },
];

export default articleData;
