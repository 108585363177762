import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import articleData from "../../data/articleData";
import ArticleHero from "../HomeComp/Panels/ArticleHero";
import Footer from "../shared/Footer";
import { IoCopyOutline } from "react-icons/io5";

const Article = () => {
  const codeRef = useRef(null);
  const { slug } = useParams();
  const article = articleData.find((article) => article.slug === slug);

  if (!article) {
    return <div>Article not found</div>;
  }

  const handleCopyClick = () => {
    if (codeRef.current) {
      const textArea = document.createElement("textarea");
      textArea.value = codeRef.current.innerText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      // Optionally, you can provide some feedback to the user after copying:
      // alert('Code copied to clipboard!');
    }
  };
  return (
    <div className="bg-[#ebebeb]">
      <ArticleHero
        title={article.title}
        author={article.author}
        date={article.date}
      />
      <div className="m-3 rounded-lg bg-gray-50 text-gray-950 p-5 lg:py-24">
        {article.content.map((contentItem, index) => {
          switch (contentItem.type) {
            case "paragraph":
              return (
                <div className="text-left flex justify-center items-center">
                  <p
                    key={index}
                    className="text-base lg:text-xl p-5 max-w-screen-xl text-gray-700 leading-relaxed"
                  >
                    {contentItem.content}
                  </p>
                </div>
              );
            case "heading":
              return (
                <div className="text-left flex justify-center items-center mx-auto">
                  <p
                    key={index}
                    className="text-2xl lg:text-4xl p-5 max-w-screen-xl text-gray-800 leading-relaxed"
                  >
                    {contentItem.content}
                  </p>
                </div>
              );
            case "image":
              return (
                <div className="flex justify-center items-center mx-auto rounded-lg py-12">
                  <img
                    key={index}
                    src={contentItem.src}
                    alt={contentItem.alt}
                    className="lg:max-w-screen-xl rounded-lg max-h-[720px] "
                  />
                </div>
              );
            case "code":
              return (
                <div className="grid grid-cols-2 items-start justify-between max-w-2xl mx-auto my-6 rounded-lg bg-kbg/90 text-gray-50">
                  <pre className="p-6 text-left">
                    <code ref={codeRef}>{contentItem.content}</code>
                  </pre>
                  <button
                    onClick={handleCopyClick}
                    className="flex justify-end p-3"
                  >
                    <IoCopyOutline className="text-2xl text-gray-400 hover:text-gray-500" />
                  </button>
                </div>
              );
            case "blockquote":
              return <blockquote key={index}>{contentItem.content}</blockquote>;
            // Add cases for other content types
            default:
              return null;
          }
        })}
        <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 justify-center text-base lg:text-2xl lg:space-x-12 items-center text-gray-400 pt-16 pb-12 lg:pb-0">
          <p>Author: {article.author}</p>
          <p>Date: {article.date}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Article;
