import React from "react";
import Navbar from "../shared/Navbar";
import bgCover from "../../assets/bg-cover.webm";

const Hero = () => {
  return (
    <div className="m-3 bg-kbg rounded-lg text-gray-50 lg:h-screen flex flex-col relative z-0">
      <Navbar />
      <div className="lg:flex-1 flex items-end justify-center my-32 md:my-0 relative z-10">
        <div className="flex flex-col text-left items-center justify-center space-y-10 max-w-7xl lg:text-center lg:mb-20 px-6 lg:px-1">
          <a
            className="primary-btn text-sm lg:text-lg flex items-center"
            href="#pricing"
          >
            <span className="mr-2 text-sm lg:text-xl">🤑</span>Hefty discount
            incoming <span className="ml-2 text-sm lg:text-xl">&rarr;</span>
          </a>
          <h1 className="text-5xl lg:text-8xl font-light md:font-medium lg:px-20">
            Your startup's in-house AI department.
          </h1>
          <p className="text-sm md:text-lg md:max-w-3xl">
            From chaos to clarity, K&M crafts bespoke AI/ML and deep learning
            solutions exclusively for startups. It's hard and costly to do it
            alone. We're here to help!
          </p>
          <a
            href="/contact"
            className="w-full py-3 bg-gray-50 rounded-full text-gray-950 hover:bg-gray-800 hover:text-gray-50 transition-all duration-300 text-center md:hidden"
          >
            Let's talk &rarr;
          </a>
        </div>
      </div>

      <video
        autoPlay
        muted
        loop
        className="absolute inset-0 object-cover w-full h-full -z-10 brightness-50 rounded-lg"
      >
        <source src={bgCover} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default Hero;
