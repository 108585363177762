import React, { useEffect, useState } from "react";
import AboutPanel from "../HomeComp/Panels/AboutPanel";
import meeting from "../../assets/meeting.jpg";

const Form = () => {
  const month_name = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const date = new Date();
  let month = month_name[date.getMonth()];
  const dayOfMonth = date.getDate();

  const [remainingSpots, setRemainingSpots] = useState(2);
  const [isStartup, setIsStartup] = useState(true);

  const handleStartupChange = (value) => {
    setIsStartup(value === true);
  };

  useEffect(() => {
    let updatedSpots;
    if (dayOfMonth <= 5) {
      updatedSpots = "5";
    } else if (dayOfMonth <= 12) {
      updatedSpots = "4/5";
    } else if (dayOfMonth <= 18) {
      updatedSpots = "3/5";
    } else if (dayOfMonth <= 24) {
      updatedSpots = "2/5";
    } else {
      updatedSpots = "1/5";
    }
    setRemainingSpots(updatedSpots);
  }, [dayOfMonth]);
  return (
    <div>
      <AboutPanel panel_name={"Let's talk"} />
      <div className="m-3 rounded-lg bg-gray-50 text-gray-950 pt-5 px-5 flex flex-col space-y-1">
        <div className="grid grid-cols-1 lg:grid-cols-12 h-auto">
          <div className="col-span-8">
            <div className="p-3 lg:p-7">
              <form
                action="https://getform.io/f/05031cc9-729c-40c7-8279-c824dabf616a"
                method="POST"
              >
                <div className="grid grid-cols-1 gap-y-12">
                  <div className="flex flex-col items-start justify-center text-left space-y-6">
                    <h1 className="text-3xl lg:text-5xl text-medium max-w-xl">
                      AI development and maintenance is hefty.
                    </h1>
                    <p className="text-gray-600">
                      Let us help you solve problems in the most efficient
                      manner.
                      <br /> Limited seats available. Minimum tenure: 6 Months.
                      (Quality &gt;&gt; Quantity of clients)
                    </p>
                  </div>
                  <div className="grid grid-cols-1 space-y-4">
                    <div className="w-full flex flex-col items-start justify-center space-y-4 pt-6 py-4">
                      <p className="text-xl lg:text-2xl">Are you a startup?</p>
                      <ul className="items-center w-full grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-y-0 lg:gap-x-4">
                        <li className="w-full border-gray-200 border rounded-lg">
                          <div className="flex items-center ps-3">
                            <input
                              id="horizontal-list-radio-yes"
                              type="radio"
                              value="yes"
                              name="list-radio"
                              onClick={() => handleStartupChange(true)}
                              className="w-5 h-5 text-blue-600 bg-transparent border-gray-300 focus:ring-blue-500  ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 "
                            />
                            <label
                              htmlFor="horizontal-list-radio-yes"
                              className="w-full py-3 ms-2"
                            >
                              Yes
                            </label>
                          </div>
                        </li>
                        <li className="w-full border-gray-200 border rounded-lg">
                          <div className="flex items-center ps-3">
                            <input
                              id="horizontal-list-radio-no"
                              type="radio"
                              value="no"
                              name="list-radio"
                              onClick={() => handleStartupChange(false)}
                              className="w-5 h-5 text-blue-600 bg-transparent border-gray-300 focus:ring-blue-500  ring-offset-gray-700 focus:ring-offset-gray-700 focus:ring-2 "
                            />
                            <label
                              htmlFor="horizontal-list-radio-no"
                              className="w-full py-3 ms-2  "
                            >
                              No
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <p className="text-xl lg:text-2xl">About you</p>

                    {isStartup ? (
                      <div className="grid grid-cols-1 gap-y-6">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-x-4 justify-between items-center">
                          <div className="w-full">
                            <input
                              type="text"
                              className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                              placeholder="Name"
                              name="name"
                              id="name"
                              required
                            />
                          </div>
                          <div className="w-full">
                            <input
                              type="text"
                              className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                              placeholder="Company"
                              name="company"
                              id="company"
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <input
                            type="email"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Email"
                            name="email"
                            id="email"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="LinkedIn URL"
                            name="linkedin"
                            id="linkedin"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="When did you incorporate?"
                            name="date-of-incorporation"
                            id="date-of-incorporation"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Describe your company in one sentence"
                            name="company-desc-in-one-sent"
                            id="company-desc-in-one-sent"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <textarea
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Who writes the code?"
                            name="who-writes-the-code"
                            id="who-writes-the-code"
                            cols={30}
                            rows={5}
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Please provide a link to the product (Optional)"
                            name="link-to-product"
                            id="link-to-product"
                          />
                        </div>
                        <div className="w-full">
                          <textarea
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="How far along are you?"
                            name="how-far-are-you"
                            id="how-far-are-you"
                            cols={30}
                            rows={5}
                            required
                          />
                        </div>
                        <div className="w-full">
                          <textarea
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="What tech stack are you using?"
                            name="tech-stack"
                            id="tech-stack"
                            cols={30}
                            rows={5}
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="What is your current revenue?"
                            name="revenue"
                            id="revenue"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Have you taken any investments yet? If yes, how much?"
                            name="investments-funding"
                            id="investments-funding"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <textarea
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Why did you pick this idea?"
                            name="why-picked-this-idea"
                            id="why-picked-this-idea"
                            cols={30}
                            rows={5}
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="How did you hear about K&M? (Optional)"
                            name="how-did-you-hear-about-knm"
                            id="how-did-you-hear-about-knm"
                          />
                        </div>
                        <div className="w-full">
                          <button
                            type="submit"
                            className="px-5 py-2 rounded-full bg-gray-950 text-gray-50 hover:text-gray-900 hover:bg-gray-200 transition-all duration-300"
                          >
                            Submit application
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 gap-y-6">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-x-4 justify-between items-center">
                          <div className="w-full">
                            <input
                              type="text"
                              className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                              placeholder="Name"
                              name="name"
                              id="name"
                              required
                            />
                          </div>
                          <div className="w-full">
                            <input
                              type="text"
                              className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                              placeholder="Company"
                              name="company"
                              id="company"
                              required
                            />
                          </div>
                        </div>
                        <div className="w-full">
                          <input
                            type="email"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Email"
                            name="email"
                            id="email"
                            required
                          />
                        </div>
                        <div className="w-full">
                          <input
                            type="text"
                            name="budget"
                            id="budget"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                            placeholder="Budget (if any)"
                          />
                        </div>

                        <div className="w-full">
                          <textarea
                            name="message"
                            id="message"
                            cols="30"
                            rows="8"
                            required
                            placeholder="Details/requirements"
                            className="w-full border rounded-lg border-gray-200 bg-transparent py-3 px-4 placeholder:text-gray-500 focus:ring-1 focus:ring-blue-500"
                          ></textarea>
                        </div>
                        <div className="w-full">
                          <button
                            type="submit"
                            className="px-5 py-2 rounded-full bg-gray-950 text-gray-50 hover:text-gray-900 hover:bg-gray-200 transition-all duration-300"
                          >
                            Submit inquiry
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </form>
              <p className="py-12">
                Need to send in extra information, files or peculiar details?{" "}
                <br className="hidden lg:block" />
                <a href="mailto:hello@knmfirm.com">
                  <span className="underline underline-offset-4 hover:text-gray-500 transition-all duration-300">
                    Shoot an email
                  </span>
                </a>{" "}
                right away!
              </p>
            </div>
          </div>
          <div className="col-span-4 relative rounded-lg overflow-hidden">
            <img
              src={meeting}
              alt="cover"
              className="absolute inset-0 w-full h-full object-cover rounded-lg"
            />
          </div>
          <div className="col-span-12">
            <div className="pt-12 px-5 pb-5 lg:pb-0 overflow-hidden">
              <div className="lg:h-24 overflow-hidden">
                <h1 className="font-semibold lg:font-bold text-[2.5rem] lg:text-[6.6rem] text-gray-200 tracking-tight text-center leading-tight">
                  <span className="text-gray-400">{remainingSpots}</span> Spots
                  Left For <span className="text-gray-400">{month}</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
