import React from "react";

const Showcase = () => {
  const works = [
    {
      imgURL: "https://dr.savee-cdn.com/things/5/a/d4e4db40230565d83bbb49.webp",
      imgALT: "cover",
    },
    {
      imgURL: "https://dr.savee-cdn.com/things/6/2/93323d68a9956130fe00b0.webp",
      imgALT: "cover",
    },
    {
      imgURL: "https://dr.savee-cdn.com/things/5/c/97ace3ba12f4725fe31029.webp",
      imgALT: "cover",
    },
    {
      imgURL: "https://dr.savee-cdn.com/things/6/5/5966900cae6c4577bef204.webp",
      imgALT: "cover",
    },
    {
      imgURL: "https://dr.savee-cdn.com/things/5/e/2079e8e572f948ea08483e.webp",
      imgALT: "cover",
    },

    {
      imgURL: "https://dr.savee-cdn.com/things/5/a/82f104440ec9476be200c7.webp",
      imgALT: "cover",
    },
  ];
  return (
    <div className="m-3 rounded-lg p-5 bg-gray-50 relative">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-2 md:gap-x-4">
        {works.map((work, index) => (
          <div
            key={index}
            className="relative rounded-lg overflow-hidden"
            style={{ height: "180px" }}
          >
            <img
              src={work.imgURL}
              alt={work.imgALT}
              className="w-full h-full object-cover rounded-lg brightness-75"
            />
          </div>
        ))}
      </div>
      <div className="absolute inset-0 flex items-center justify-center">
        <a
          href="/work"
          className="text-lg px-5 py-2 rounded-full bg-gray-950 text-gray-50 hover:bg-gray-50 hover:text-gray-950 transition-all duration-300"
        >
          More Work &rarr;
        </a>
      </div>
    </div>
  );
};

export default Showcase;
