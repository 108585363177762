import React from "react";
import Navbar from "../../shared/Navbar";

const ArticleHero = ({ title, author, cover, date }) => {
  return (
    <div className="m-3 bg-kbg rounded-lg text-gray-50 flex flex-col relative z-0">
      <Navbar />
      <div className="lg:flex-1 flex items-center justify-center py-48 md:my-0 relative z-10">
        <div className="flex flex-col text-center items-center justify-center space-y-10 max-w-7xl lg:text-center px-6">
          <h1 className="text-2xl lg:text-5xl font-light md:font-medium">
            {title}
          </h1>
          <div className="flex space-x-6 lg:space-x-12 lg:justify-between items-center">
            <p className="text-sm md:text-xl px-2">{author}</p>
            <p className="text-sm md:text-xl px-2 text-gray-400">{date}</p>
          </div>
        </div>
      </div>
      <div className="absolute inset-0 object-cover w-full h-full -z-10 brightness-50 opacity-30 rounded-lg overflow-hidden">
        <img
          src={cover}
          alt="cover"
          className="object-contain w-full h-full scale-50"
        />
      </div>
    </div>
  );
};

export default ArticleHero;
