import React from "react";
import blogCards from "../../data/blogCard";

const Blog = () => {
  return (
    <div className="m-3 rounded-lg bg-gray-50 text-gray-950">
      <section className="lg:p-5">
        <div className="">
          <div className="lg:p-5 text-left hidden">
            <h2 className="mb-4 text-3xl lg:text-6xl tracking-tight font-medium">
              Pure work. In it's perfect state.
            </h2>
            <p className="text-xl text-gray-600">
              We use an agile approach to test assumptions and connect with the
              needs of your audience early and often.
            </p>
          </div>
          <div className="grid gap-y-4 lg:gap-4 lg:grid-cols-2 grid-cols-1 p-4 lg:p-12">
            {blogCards.map((card, index) => (
              <article
                className="p-6 rounded-lg border  bg-gray-100 border-gray-300 flex flex-col space-y-6 py-8 justify-between"
                key={index}
              >
                <div className="flex flex-col space-y-6">
                  <div className="flex justify-between items-center mb-5 text-gray-500">
                    <span className=" text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-lg bg-blue-200 text-blue-800">
                      <svg
                        className="mr-1 w-3 h-3"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                      </svg>
                      {card.tag}
                    </span>
                    <span className="text-sm">{card.date}</span>
                  </div>
                  <a href={`/blog/${card.slug}`}>
                    <div className="overflow-hidden rounded-lg h-auto lg:h-[380px]">
                      <img
                        src={card.main_img}
                        alt="cover"
                        className="rounded-lg hover:scale-110 transition-all duration-300"
                      />
                    </div>
                  </a>
                  <h2 className="mb-2 text-2xl lg:text-4xl font-medium">
                    <a
                      href={`/blog/${card.slug}`}
                      className="hover:underline underline-offset-4"
                    >
                      {card.title}
                    </a>
                  </h2>
                  <p className="mb-5 text-gray-600 max-w-3xl text-base lg:text-lg">
                    {card.abstract}
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 justify-between items-center">
                  <div className="flex items-center space-x-4">
                    <img
                      className="w-7 h-7 rounded-full"
                      src={card.author_img}
                      alt={card.author}
                    />
                    <span className="font-medium ">{card.author}</span>
                  </div>
                  <a
                    href={`/blog/${card.slug}`}
                    className="primary-btn w-full lg:w-auto text-center"
                  >
                    Read more
                  </a>
                </div>
              </article>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
