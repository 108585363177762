import React from "react";

const AboutPanel = ({ panel_name }) => {
  return (
    <div className="m-3 rounded-lg bg-gray-50">
      <div className="w-full flex space-x-3 lg:space-x-4 px-5 py-4 justify-start items-center">
        <div className="h-2 w-2 lg:h-3 lg:w-3 rounded-full bg-blue-600" />
        <h1 className="uppercase text-lg lg:text-3xl text-gray-600">
          {panel_name}
        </h1>
      </div>
    </div>
  );
};

export default AboutPanel;
