import React from "react";
import AboutPanel from "./Panels/AboutPanel";
import BentoGrid from "./Panels/BentoGrid";
import Marq from "./Panels/Marq";

const Service = () => {
  return (
    <div>
      <AboutPanel panel_name="Our Services" />
      <div
        className="m-3 rounded-lg bg-gray-50 text-gray-950 pb-5"
        id="services"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 px-5">
          <div>
            <div className="pt-12 flex flex-col items-start space-y-5">
              <h1 className="text-3xl lg:text-5xl ">
                A <span className="text-gray-500">one-stop</span> solution for
                turning all your creativity and planning into real-life{" "}
                <span className="text-gray-500">applications</span>.
              </h1>
              <p className="text-gray-600 hidden">
                You can also use variant modifiers to target media queries like
                responsive breakpoints, dark mode, prefers-reduced-motion, and
                more. For example, use lg:divide-y-8 to apply the divide-y-8
                utility at only medium screen sizes and above
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start">
            <div>
              <div className="flex flex-col space-y-5 pt-12 pb-6">
                <div className="flex flex-col space-y-4">
                  <p className="text-2xl">What's in the box?</p>
                  <p className="text-gray-600 leading-relaxed">
                    K&M offers unlimited support with building complex AI
                    solutions for your startup. <br />
                    You name it, and we have it.
                  </p>
                </div>

                <div className="grid grid-cols-1 space-y-5 lg:space-y-0 lg:grid-cols-2 pb-12">
                  <div>
                    <ul className="flex flex-col space-y-5">
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Custom models
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Unlimited revisions
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Pattern recognition tasks
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        ML Ops
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Pattern analysis
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Relationship discovery
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Unlimited iterations
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="flex flex-col space-y-5">
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Exploratory Data Analysis
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        NLP/NLU
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Representation Learning
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Deep learning
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Actionable insights
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Beautifully crafted reports
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              <div className="flex flex-col space-y-5 pb-12 pt-6">
                <h1 className="text-2xl">Exploratory Data Analysis</h1>
                <p className="text-gray-600 leading-relaxed">
                  Whether it's deciphering user behavior or uncovering product
                  affinities, our EDA services provide actionable insights to
                  fuel your decision-making process
                </p>
                <div className="grid grid-cols-1 space-y-5 lg:space-y-0 lg:grid-cols-2">
                  <div>
                    <ul className="flex flex-col space-y-5">
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Pattern analysis
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Relationship discovery
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Unlimited iterations
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="flex flex-col space-y-5">
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Actionable insights
                      </li>
                      <li className="text-sm lg:text-base">
                        <span className="mr-3  text-gray-400">&#8594;</span>
                        Beautifully crafted reports
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <BentoGrid />
        <Marq />
      </div>
    </div>
  );
};

export default Service;
