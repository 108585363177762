import React from "react";
import AboutPanel from "./Panels/AboutPanel";
import signature from "../../assets/Signature.svg";

const About = () => {
  return (
    <div>
      <AboutPanel panel_name="About us" />
      <div className="m-3 rounded-lg bg-gray-50 text-gray-950" id="about">
        <div className="grid grid-cols-1 lg:grid-cols-5 px-5 py-12">
          <div></div>
          <div></div>
          <div className="col-span-3 divide-y-2">
            <div className="pb-12 lg:pb-20">
              <h1 className="text-3xl lg:text-5xl ">
                <span className="text-gray-500">Innovation</span> and{" "}
                <span className="text-gray-500">efficiency</span> combined.
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-y-12 lg:gap-y-0 lg:gap-x-8 lg:grid-cols-2 pt-8">
              <div className="flex flex-col items-start space-y-5">
                <h1 className="">More than a firm</h1>
                <p className="text-gray-600 leading-relaxed">
                  We pride ourselves on crafting AI software solutions that
                  transcend boundaries, empowering businesses to thrive in an
                  ever-evolving landscape. Our dedication lies in creating
                  cutting-edge technology that doesn't just meet industry
                  standards but sets new benchmarks altogether.
                </p>
              </div>
              <div className="flex flex-col items-start space-y-5">
                <h1 className="">Founder's note</h1>
                <p className="text-gray-600 leading-relaxed">
                  At K&M, our journey is centered around you – your aspirations,
                  your challenges, and your success. As the founder, I am driven
                  by an unwavering passion for innovation and an unyielding
                  commitment to crafting solutions that exceed expectations.
                </p>
                <p className="text-gray-600 leading-relaxed">
                  My ethos is simple: to harness the power of AI not just to
                  meet your needs but to anticipate and exceed them. My
                  fascination with technology began early, inspired by pioneers
                  who dared to reimagine possibilities. Their courage and vision
                  echo in every line of code we write, in every solution we
                  design.
                </p>
                <div className=" overflow-hidden w-full py-4">
                  <img
                    src={signature}
                    alt="Siddhesh Kulthe's signature"
                    className="w-2/3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
