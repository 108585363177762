import React from "react";
import AboutPanel from "./Panels/AboutPanel";

const steps = [
  {
    title: "Startups",
    stepInfo: [
      {
        stepCount: "1",
        stepDetail: "Fill the application form",
      },
      {
        stepCount: "2",
        stepDetail: "Founder's pitching call",
      },
      {
        stepCount: "3",
        stepDetail: "Understand strategic requirements",
      },
      {
        stepCount: "4",
        stepDetail: "Discuss technical requirements",
      },
      {
        stepCount: "5",
        stepDetail: "Acceptance and contract sign",
      },
      {
        stepCount: "6",
        stepDetail: "Development starts",
      },
    ],
    bgColor: "bg-gray-50",
    txtColor: "text-gray-950",
    stepbgColor: "bg-gray-200",
    linkText: "Apply Now",
  },
  {
    title: "Enterprises",
    stepInfo: [
      {
        stepCount: "1",
        stepDetail: "Send us a message",
        link: "",
        defaultColor: "",
      },
      {
        stepCount: "2",
        stepDetail: "Qualification and requirements call",
        link: "",
        defaultColor: "",
      },
      {
        stepCount: "3",
        stepDetail: "Tenure and deal sign",
        link: "",
        defaultColor: "",
      },
      {
        stepCount: "4",
        stepDetail: "Development starts",
        link: "",
        defaultColor: "",
      },
    ],
    bgColor: "bg-gray-50",
    txtColor: "text-gray-950",
    stepbgColor: "bg-gray-200",
    linkText: "Get Started",
  },
];

const Process = () => {
  return (
    <div className="">
      <AboutPanel panel_name="Process" />
      <div className="m-3 rounded-lg bg-gray-50 text-gray-950 p-5 flex flex-col space-y-3">
        <h1 className="text-3xl lg:text-5xl font-medium">
          Steps to Work With us
        </h1>
        <p className="text-gray-600">
          Looking forward to start your development soon? Here's how.
        </p>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 m-3 gap-4">
        {steps.map((step, index) => (
          <div key={index} className="">
            <div className={`p-5 ${step.bgColor} ${step.txtColor} rounded-lg`}>
              <div className="flex flex-col items-start p-2 lg:p-5 space-y-4">
                <h1 className="text-2xl lg:text-3xl mb-0 lg:mb-1 font-medium">
                  {step.title}
                </h1>
                <div className="flex flex-col items-stretch space-y-3 w-full">
                  {step.stepInfo.map((stepdecal, index) => (
                    <div
                      key={index}
                      className={`${step.stepbgColor} ${step.txtColor} transition duration-300 rounded-2xl p-4 text-gray-50 w-full flex items-center justify-start space-x-3 lg:space-x-6`}
                    >
                      <h1 className="text-xl lg:text-3xl ">
                        {stepdecal.stepCount}
                      </h1>
                      <p className="text-base lg:text-lg">
                        {stepdecal.stepDetail}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="flex w-full justify-end">
                  <a
                    href="/contact"
                    className="px-5 w-full lg:w-auto py-2 rounded-full text-gray-50 bg-gray-950 hover:text-gray-950 hover:bg-gray-200 transition-all duration-300 text-center mx-4 md:mx-0"
                  >
                    {step.linkText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Process;
