import React from "react";
import SharedHero from "../HomeComp/Panels/SharedHero";
import Form from "../LtComp/Form";
import Footer from "../shared/Footer";
import talkCover from "../../assets/talk.svg";

const LetsTalk = () => {
  return (
    <div className="bg-[#ebebeb]">
      <SharedHero
        title={<>Fastrack to infinite growth.</>}
        subtitle={
          "No surprises, no hidden fees. Just straight forward awesomeness."
        }
        cover={talkCover}
      />
      <Form />
      <Footer />
    </div>
  );
};

export default LetsTalk;
