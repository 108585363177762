import React, { useState } from "react";
import AboutPanel from "./Panels/AboutPanel";
import { FaAngleUp } from "react-icons/fa6";

const Faq = () => {
  const [expandedIndexes, setExpandedIndexes] = useState([]);

  const questions = [
    {
      question:
        "What is your approach to integrating AI into startups, and how do you ensure seamless integration with existing systems?",
      answer:
        "Our approach to integrating AI into startups is methodical and tailored to each client's unique requirements. With our experienced team of AI, ML, DL, and RL engineers, we conduct a thorough assessment of the startup's existing systems and infrastructure. We work closely with our clients to understand their business goals and challenges, allowing us to identify integration points that maximize the impact of AI on their operations. Throughout the process, we prioritize seamless integration by leveraging APIs, microservices architecture, and other best practices to minimize disruptions and ensure compatibility with existing systems.",
    },
    {
      question:
        "How do you continuously optimize AI solutions to improve performance and adapt to changing business needs?",
      answer:
        "Continuous optimization is at the core of our service offering. Our engineers closely monitor the performance of AI solutions deployed within our clients' startups, collecting data and feedback to identify areas for improvement. Through a combination of algorithmic refinement, model tuning, and feature engineering, we iteratively enhance the performance of AI systems to deliver better outcomes over time. Additionally, our proactive approach enables us to adapt quickly to changing business needs, ensuring that our solutions remain aligned with our clients' evolving requirements.",
    },
    {
      question:
        "What level of support and communication can we expect throughout the 6-month tenure?",
      answer:
        "Throughout the 6-month tenure, our clients can expect dedicated support from our experienced team of engineers and product managers. We maintain open lines of communication, providing regular progress updates, and soliciting feedback to ensure that our clients are informed and involved at every stage of the engagement. Our product managers are the primary point of contact for clients, and they are equipped with both technical expertise in AI and strong communication skills. While clients do not have direct access to our engineers, they can freely discuss any aspect of their company, project, or requirements with our product managers. Our product managers serve as liaisons between clients and our technical team, ensuring that client needs are effectively communicated and addressed. Whether it's addressing technical issues, refining AI models, or discussing strategic priorities, our product managers are committed to providing responsive and proactive support to help our clients succeed.",
    },
    {
      question:
        "What measures do you have in place to ensure data security and compliance with regulations throughout the integration process?",
      answer:
        "Data security and compliance are paramount considerations in our work. We employ a comprehensive approach to safeguarding data, encompassing encryption, access controls, regular audits, and adherence to industry regulations such as GDPR. Our team undergoes rigorous training to stay abreast of the latest security threats and best practices, ensuring that our clients' data remains confidential and secure throughout the integration process.",
    },
    {
      question: "I have questions not listed here",
      answer:
        'Feel free to reach out to us via email: <a href="mailto:siddhesh@kulthe.co">siddhesh@kulthe.co</a> to initiate the conversation and explore how K&M can contribute to the success of your ML and Deep Learning projects.',
    },
  ];

  const toggleExpand = (index) => {
    if (expandedIndexes.includes(index)) {
      setExpandedIndexes(expandedIndexes.filter((i) => i !== index));
    } else {
      setExpandedIndexes([...expandedIndexes, index]);
    }
  };

  const isExpanded = (index) => {
    return expandedIndexes.includes(index);
  };

  return (
    <div>
      <AboutPanel panel_name="FAQ" />
      <div className="m-3 rounded-lg bg-gray-50 text-gray-950 p-5 flex flex-col space-y-3">
        <div className="flex flex-col items-center justify-center p-5 space-y-5 text-center">
          <h1 className="capitalize text-3xl lg:text-5xl font-medium">
            Have any questions?
          </h1>
          <p className="text-gray-600">Let us answer some for you.</p>
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-1 gap-y-4 lg:gap-y-0 lg:gap-4 m-3">
          <div className="bg-gray-50 p-3 lg:p-5 pb-7 rounded-lg">
            <div className=" grid grid-cols-1 gap-y-4 mx-auto lg:max-w-screen-2xl lg:p-5">
              {questions.map((qna, index) => (
                <div
                  className="border-gray-200 border-b-2 cursor-pointer"
                  onClick={() => toggleExpand(index)}
                  key={index}
                >
                  <div className="p-5 lg:text-xl relative">
                    <div className="w-5/6">
                      <p>{qna.question}</p>
                    </div>
                    <button
                      aria-label="question-expander"
                      className="text-xl absolute top-0 right-0 p-5 focus:outline-none"
                    >
                      <FaAngleUp
                        className={`w-5 text-gray-800 transition-all ease-in duration-300 ${
                          isExpanded(index) ? "" : "-rotate-180"
                        }`}
                      />
                    </button>
                  </div>
                  <div
                    className={`${
                      isExpanded(index) ? "display" : "hidden"
                    } px-5 pb-5 text-gray-600 leading-relaxed text-sm lg:text-base max-w-screen-xl`}
                  >
                    <p dangerouslySetInnerHTML={{ __html: qna.answer }} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
