import React from "react";
import notification from "../../../assets/iOS.png";
import frameGrid from "../../../assets/frame.svg";
import graph from "../../../assets/graph.svg";

const BentoGrid = () => {
  return (
    <div className="px-5">
      <div className="grid grid-cols-1 lg:grid-cols-5 lg:gap-4 gap-y-4 lg:gap-y-0">
        <div className="col-span-2 grid grid-cols-1 gap-y-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex flex-col p-6 items-start space-y-12 bg-kbg rounded-lg text-gray-50">
              <h1 className="text-xl">Credibility</h1>
              <div className="flex flex-col space-y-2">
                <h1 className="text-6xl lg:text-8xl font-medium">25+</h1>
                <p className="pr-1 text-2xl lg:text-lg">
                  Startups already trust <strong>K&M</strong> for their Deep
                  Learning Projects
                </p>
              </div>
            </div>
            <div className="border-2 border-gray-300 flex flex-col p-6 items-start space-y-12 rounded-lg">
              <h1 className="text-xl">Experience</h1>
              <div className="flex flex-col space-y-2">
                <h1 className="text-6xl lg:text-8xl font-medium">57+</h1>
                <p className="pr-1 text-2xl lg:text-lg">
                  In demand projects completed. Dynamic and complex, we've
                  solved it all.
                </p>
              </div>
            </div>
          </div>
          <div className="relative flex flex-col p-6 items-start space-y-12 bg-kbg rounded-lg text-gray-50 pt-64">
            <h1 className="text-4xl font-normal lg:text-5xl relative z-10">
              <span className="font-medium">$64K</span> saved by clients with
              ROI as high as <span className="font-medium">37.8%</span>
            </h1>
            <div
              className="absolute inset-0 bg-cover opacity-50 bg-blend-darken"
              style={{ backgroundImage: `url(${graph})` }}
              alt="frame graph cover"
            ></div>
          </div>
        </div>
        <div className="col-span-3 text-gray-50 rounded-lg bg-kbg relative">
          <div className="relative z-10 flex flex-col items-center justify-center pt-5 space-y-12">
            <h1 className="pt-12 lg:pt-0 text-3xl lg:text-5xl font-medium max-w-lg text-center">
              Create everything perfectly.
            </h1>
            <img
              src={notification}
              alt="success notification"
              className="rounded-lg h-[300px] lg:h-[550px]"
            />
          </div>

          <div
            className="absolute inset-0 bg-cover bg-center brightness-50 opacity-40 bg-blend-darken"
            style={{ backgroundImage: `url(${frameGrid})` }}
            alt="frame grid cover"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BentoGrid;
