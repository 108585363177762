import React from "react";
import SharedHero from "../HomeComp/Panels/SharedHero";
import Footer from "../shared/Footer";

const Terms = () => {
  return (
    <div>
      {" "}
      <SharedHero
        title={"Terms of Service"}
        subtitle={"Last updated: January 01, 2024"}
      />
      <div className="m-3 rounded-lg bg-gray-50 text-gray-950 p-5 flex flex-col space-y-3">
        <div className="py-12 grid grid-cols-1 justify-center items-center mx-auto max-w-screen-lg text-left space-y-6">
          <h2 className="text-xl lg:text-3xl">1. Terms</h2>

          <p>
            By accessing this Website, accessible from https://kulthe.co or
            http://kulthe.co or https://www.kulthe.co, you are agreeing to be
            bound by these Website Terms and Conditions of Use and agree that
            you are responsible for the agreement with any applicable local
            laws. If you disagree with any of these terms, you are prohibited
            from accessing this site. The materials contained in this Website
            are protected by copyright and trade mark law.
          </p>

          <h2 className="text-xl lg:text-3xl">2. Use License</h2>

          <p>
            Permission is granted to temporarily download one copy of the
            materials on Kulthe Media, Limited's Website for personal,
            non-commercial transitory viewing only. This is the grant of a
            license, not a transfer of title, and under this license you may
            not:
          </p>

          <ul>
            <li className="capitalize">&rarr; modify or copy the materials;</li>
            <li className="capitalize">
              &rarr; use the materials for any commercial purpose or for any
              public display;
            </li>
            <li className="capitalize">
              &rarr; attempt to reverse engineer any software contained on
              Kulthe Media, Limited's Website;
            </li>
            <li className="capitalize">
              &rarr; remove any copyright or other proprietary notations from
              the materials; or
            </li>
            <li className="capitalize">
              &rarr; transferring the materials to another person or "mirror"
              the materials on any other server.
            </li>
          </ul>

          <p>
            This will let Kulthe Media, Limited to terminate upon violations of
            any of these restrictions. Upon termination, your viewing right will
            also be terminated and you should destroy any downloaded materials
            in your possession whether it is printed or electronic format.{" "}
          </p>

          <h2 className="text-xl lg:text-3xl">3. Disclaimer</h2>

          <p>
            All the materials on Kulthe Media, Limited's Website are provided
            "as is". Kulthe Media, Limited makes no warranties, may it be
            expressed or implied, therefore negates all other warranties.
            Furthermore, Kulthe Media, Limited does not make any representations
            concerning the accuracy or reliability of the use of the materials
            on its Website or otherwise relating to such materials or any sites
            linked to this Website.
          </p>

          <h2 className="text-xl lg:text-3xl">4. Limitations</h2>

          <p>
            Kulthe Media, Limited or its suppliers will not be hold accountable
            for any damages that will arise with the use or inability to use the
            materials on Kulthe Media, Limited's Website, even if Kulthe Media,
            Limited or an authorize representative of this Website has been
            notified, orally or written, of the possibility of such damage. Some
            jurisdiction does not allow limitations on implied warranties or
            limitations of liability for incidental damages, these limitations
            may not apply to you.
          </p>

          <h2 className="text-xl lg:text-3xl">5. Revisions and Errata</h2>

          <p>
            The materials appearing on Kulthe Media, Limited's Website may
            include technical, typographical, or photographic errors. Kulthe
            Media, Limited will not promise that any of the materials in this
            Website are accurate, complete, or current. Kulthe Media, Limited
            may change the materials contained on its Website at any time
            without notice. Kulthe Media, Limited does not make any commitment
            to update the materials.
          </p>

          <h2 className="text-xl lg:text-3xl">6. Links</h2>

          <p>
            Kulthe Media, Limited has not reviewed all of the sites linked to
            its Website and is not responsible for the contents of any such
            linked site. The presence of any link does not imply endorsement by
            Kulthe Media, Limited of the site. The use of any linked website is
            at the user's own risk.
          </p>

          <h2 className="text-xl lg:text-3xl">
            7. Site Terms of Use Modifications
          </h2>

          <p>
            Kulthe Media, Limited may revise these Terms of Use for its Website
            at any time without prior notice. By using this Website, you are
            agreeing to be bound by the current version of these Terms and
            Conditions of Use.
          </p>

          <h2 className="text-xl lg:text-3xl">8. Your Privacy</h2>

          <p>Please read our Privacy Policy.</p>

          <h2 className="text-xl lg:text-3xl">9. Governing Law</h2>

          <p>
            Any claim related to Kulthe Media, Limited's Website shall be
            governed by the laws of the Great Britain without regards to its
            conflict of law provisions.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
